const consoleContent = [
  {
    consoleMessage: 'Software Developer',
    delay: 4,
  },
  {
    consoleMessage: 'Computer Technician',
    delay: 5,
  },
  {
    consoleMessage: 'Musician',
    delay: 6,
  },
  {
    consoleMessage: 'Gamer',
    delay: 7,
  },
];

const typewriterContent = [
  {
    delay: 12,
    character: 'l',
    type: 'meth1',
    target: '.line1',
  },
  {
    delay: 13,
    character: 'o',
    type: 'meth1',
    target: '.line1',
  },
  {
    delay: 14,
    character: 'g',
    type: 'meth1',
    target: '.line1',
  },
  {
    delay: 15,
    character: 'W',
    type: 'meth1',
    target: '.line1',
  },
  {
    delay: 16,
    character: 'h',
    type: 'meth1',
    target: '.line1',
  },
  {
    delay: 17,
    character: 'o',
    type: 'meth1',
    target: '.line1',
  },
  {
    delay: 18,
    character: 'I',
    type: 'meth1',
    target: '.line1',
  },
  {
    delay: 19,
    character: 's',
    type: 'meth1',
    target: '.line1',
  },
  {
    delay: 20,
    character: 'S',
    type: 'meth1',
    target: '.line1',
  },
  {
    delay: 21,
    character: 'a',
    type: 'meth1',
    target: '.line1',
  },
  {
    delay: 22,
    character: 'm',
    type: 'meth1',
    target: '.line1',
  },
  {
    delay: 23,
    character: 'u',
    type: 'meth1',
    target: '.line1',
  },
  {
    delay: 24,
    character: 'e',
    type: 'meth1',
    target: '.line1',
  },
  {
    delay: 25,
    character: 'l',
    type: 'meth1',
    target: '.line1',
  },
  {
    delay: 25,
    action: true,
    target: 'meth1',
    class: 'text-hazelgreen',
  },
  {
    delay: 26,
    character: '(',
    type: 'misc1',
    target: '.line1',
  },
  {
    delay: 26,
    action: true,
    target: 'misc1',
    class: 'text-white',
  },
  {
    delay: 27,
    character: 's',
    type: 'var1',
    target: '.line1',
  },
  {
    delay: 28,
    character: 'a',
    type: 'var1',
    target: '.line1',
  },
  {
    delay: 29,
    character: 'm',
    type: 'var1',
    target: '.line1',
  },
  {
    delay: 30,
    character: 'u',
    type: 'var1',
    target: '.line1',
  },
  {
    delay: 31,
    character: 'e',
    type: 'var1',
    target: '.line1',
  },
  {
    delay: 32,
    character: 'l',
    type: 'var1',
    target: '.line1',
  },
  {
    delay: 33,
    character: 'I',
    type: 'var1',
    target: '.line1',
  },
  {
    delay: 34,
    character: 'n',
    type: 'var1',
    target: '.line1',
  },
  {
    delay: 35,
    character: 'f',
    type: 'var1',
    target: '.line1',
  },
  {
    delay: 36,
    character: 'o',
    type: 'var1',
    target: '.line1',
  },
  {
    delay: 36,
    action: true,
    target: 'var1',
    class: 'text-hazelred',
  },
  {
    delay: 37,
    character: ')',
    type: 'misc2',
    target: '.line1',
  },
  {
    delay: 37,
    action: true,
    target: 'misc2',
    class: 'text-white',
  },
  {
    delay: 38,
    character: ';',
    type: 'misc3',
    target: '.line1',
  },
  {
    delay: 38,
    action: true,
    target: 'misc3',
    class: 'text-white',
  },
];

export default { consoleContent, typewriterContent };
